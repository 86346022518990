import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home/index.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login/index.vue')
  },
  {
    path: '/product',
    name: 'product',
    component: () => import('@/views/Product/index.vue')
  },
  {
    path: '/play',
    name: 'play',
    component: () => import('@/views/Play/index.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('@/views/Account/index.vue')
  },
  // 信息 页面
  {
    path: '/pay',
    name: 'pay',
    component: () => import('@/views/Account/pay.vue')
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/Account/order.vue')
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/Account/privacy-policy.vue')
  },
  {
    path: '/contact-us',
    name: 'contact-us',
    component: () => import('@/views/Account/contact-us.vue')
  },
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () => import('@/views/Account/terms-of-use.vue')
  },
  {
    path: '/internal-policy',
    name: 'internal-policy',
    component: () => import('@/views/Account/internal-policy.vue')
  },
  // 新优化页面
  {
    path: '/join/snr=1_4_4__more-content-login',
    name: 'join',
    component: () => import('@/views/New/join.vue')
  },
  {
    path: '/coin/snr=2_5_6__more-coin-pay',
    name: 'coin',
    component: () => import('@/views/New/coin.vue')
  },
  // 404 页面
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: () => import('@/views/404.vue')
  },
  {
    // 路由重定向
    path: '/',
    redirect: {
      path: '/',
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // 每次路由跳转之后 新页面加载在x轴和y轴的初始位置
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
})

// 全局前置守卫
router.beforeEach((to, from, next) => {

  if (to.path === '/account' && !store.state.userInfo.access_token) {
    next('/login');
  } else if (to.path === '/play' && !store.state.userInfo.access_token) {
    next('/login');
  }
  else {
    next(); // 正常跳转
  }

});

export default router
